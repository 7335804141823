import router from '@/router';
import api from '@/api/auth';

export default {
  namespaced: true,

  state: {
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    sessionToken: null,
  },

  mutations: {
    login(state, { token_type, access_token, refresh_token }) {
      const token = token_type + ' ' + access_token;
      refresh_token = refresh_token ?? '';

      state.sessionToken = null;
      state.token = token;
      state.refreshToken = refresh_token;

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refresh_token);
    },

    writeSessionToken(state, session_token) {
      state.sessionToken = session_token;
    },

    logout(state, redirect) {
      state.token = null;
      state.refreshToken = null;

      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }

      if (localStorage.getItem('refreshToken')) {
        localStorage.removeItem('refreshToken');
      }

      if (redirect) {
        router.replace({ name: 'Home' });
      }
    },

    getFreshTokens(state, { token_type, access_token, refresh_token }) {
      const token = token_type + ' ' + access_token;

      state.token = token;
      state.refreshToken = refresh_token;

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refresh_token);
    },
  },

  actions: {
    async signup({ commit }, data) {
      try {
        await api.register(data);
      } catch (err) {
        return err;
      }
    },

    async login({ commit, dispatch }, userData) {
      try {
        const tokens = await api.login(userData);

        if (tokens?.session_token) {
          commit('writeSessionToken', tokens.session_token);
        } else if (tokens.access_token) {
          commit('login', tokens);
          await dispatch('user/getUserData', {}, { root: true });
          router.replace({ name: 'Dashboard' });
        }
      } catch (err) {
        return err;
      }
    },

    async confirm({ commit, dispatch }, code) {
      try {
        const tokens = await api.confirm(code);
        commit('login', tokens);

        await dispatch('user/getUserData', {}, { root: true });
        router.replace({ name: 'Dashboard' });
      } catch (err) {
        return err;
      }
    },

    logout(store, redirect = true) {
      store.commit('logout', redirect);
      store.dispatch('clearStorage', {}, { root: true });
    },

    async getFreshToken({ commit }, token) {
      try {
        const tokens = await api.refreshToken(token);
        commit('getFreshTokens', tokens);
      } catch (err) {
        throw err;
      }
    },

    async forgotPassword({ commit }, data) {
      try {
        await api.passwordForgot(data);
      } catch (err) {
        return err;
      }
    },

    async resetPassword({ commit }, data) {
      try {
        await api.passwordReset(data);
        router.replace({ name: 'Login' });
      } catch (err) {
        throw err;
      }
    },
  },

  getters: {
    authenticated(state) {
      return Boolean(state.token);
    },
  },
};
